import { Application, ControllerConstructor } from 'stimulus'
import { AttributeObserver } from '@stimulus/mutation-observers'

const cache = new Set<string>()

const install = (
  application: Application,
  context: Record<string, () => Promise<{ default: ControllerConstructor }>>,
  mainScope: String
) => {
  const load = (el: Element) => {
    const controllerNames = el.getAttribute('data-controller')!.split(/\s+/)
    controllerNames.forEach((name) => {
      if (
        cache.has(name) ||
        application.router.modules.some((m) => m.definition.identifier === name)
      )
        return

      const contextKey = `/javascripts/controllers/${mainScope}/${name
        .replace(/--/g, '/')
        .replace(/-/g, '_')}_controller.ts`

      if (context[contextKey]) {
        cache.add(name)

        void context[contextKey]().then((module) => {
          application.register(name, module.default)
        })
      }
    })
  }

  const observer = new AttributeObserver(
    application.element,
    application.schema.controllerAttribute,
    {
      elementMatchedAttribute: load,
      elementAttributeValueChanged: load,
    }
  )

  observer.start()
}

export { install }